@charset "utf-8";
@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		@for $i from 1 through 100 {
			.fs#{$infix}-#{$i} {
				//font-size:#{$i}px;
				@include font-size($i, 'important');
			}
		}
	}
}

// @each $breakpoint in map-keys($grid-breakpoints) {
// 	@include media-breakpoint-up($breakpoint) {
// 		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
// 		@each $size, $length in $spacers {
// 			.flex-grow#{$infix}-#{$size} {
// 				flex-grow: #{$size} !important;
// 			}
// 		}
// 	}
// }

// @each $breakpoint in map-keys($grid-breakpoints) {
// 	@include media-breakpoint-up($breakpoint) {
// 		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
// 		@for $i from 1 through $grid-columns {
// 			th.col#{$infix}-#{$i},
// 			td.col#{$infix}-#{$i} {
// 				width: 100% / (12 / $i) !important;
// 				position: static;
// 			}
// 		}
// 	}
// }

// @each $breakpoint in map-keys($grid-breakpoints) {
// 	@include media-breakpoint-up($breakpoint) {
// 		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
// 		.table.table#{$infix}-vertical {
// 			&.table-bordered {
// 				border-color: transparent;
// 				tr:not(:last-child) {
// 					td {
// 						border-top-color: transparent;
// 						border-bottom-color: transparent;
// 					}
// 				}
// 				tr:last-child {
// 					td {
// 						border-top-color: transparent;
// 					}
// 				}
// 			}
// 			tr {
// 				display: block !important;
// 			}
// 			th, td {
// 				display: block !important;
// 			}
// 		}
// 		.table.table#{$infix}-normal {
// 			&.table-bordered {
// 				border-color: inherit;
// 				tr:not(:last-child) {
// 					td {
// 						border-top-color: inherit;
// 						border-bottom-color: inherit;
// 					}
// 				}
// 				tr:last-child {
// 					td {
// 						border-top-color: inherit;
// 					}
// 				}
// 			}
// 			tr {
// 				display: table-row !important;
// 			}
// 			th, td {
// 				display: table-cell !important;
// 			}
// 		}
// 	}
// }

// @each $breakpoint in map-keys($grid-breakpoints) {
// 	@include media-breakpoint-up($breakpoint) {
// 		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
// 		@each $color, $value in $theme-colors {
// 			@include bg-variant(".bg#{$infix}-#{$color}", $value);
// 		}
// 		.bg#{$infix}-white {
// 			background-color: $white !important;
// 		}

// 		.bg#{$infix}-transparent {
// 			background-color: transparent !important;
// 		}

// 	}
// }