.sc-container {
	width: 1100px;
	margin-left: auto;
	margin-right: auto;
	@include media-breakpoint-down(lg) {
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}

	@include media-breakpoint-down(sm) {
		padding-left: 0;
		padding-right: 0;
	}
}

.text-container {
	width: 500px;
	margin-left: auto;
	margin-right: auto;
	@include media-breakpoint-down(xs) {
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.image-container {
	width: 960px;
	margin-left: auto;
	margin-right: auto;
	@include media-breakpoint-down(lg) {
		width: 100%;
		padding-right: 40px;
		padding-left: 40px;
	}
	@include media-breakpoint-down(md) {
		padding-left: 0;
		padding-right: 0;
	}
}
