@charset "utf-8";

.js-in-motion {
	opacity: 0;
	transition: all 0.35s 0s ease-in-out;
	margin-top: 30px;
	@include media-breakpoint-down(sm) {
		opacity: 1;
	}
	&.show {
		opacity: 1;
		margin-top: 0;
	}
}