@charset "utf-8";

body {
	-webkit-text-size-adjust: 100%;
	font-family: $base-fonts;
	font-feature-settings: 'palt' 1;
	font-weight: 500;
	background: #f5ebd7;
	@media all and (-ms-high-contrast:none) {
		font-family: メイリオ, Meiryo, "ＭＳ Ｐゴシック", sans-serif;
	}
}

img {
	max-width: 100%;
}

// 区切りに波線をつける
.sc-wave {
	position: relative;
	margin-top: -50px;
	& > .sc-container {
		margin-top: -30px;
	}
	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 50px;
		background: url(../img/bg_section_top.svg) repeat-x center top /cover;
	}
	&.reverse {
		margin-top: 0;
		& > .sc-container {
			margin-top: 0px;
		}
		&::before {
			transform: rotate(-180deg);
		}
	}
}

// ヘッダー
.site-logo {
	width: 140px;
	padding: 0;
	margin: 0 auto;
 }
.site-header {
	transition: all 0.25s 0s ease;
	padding: 30px 0 15px;
	@include media-breakpoint-down(sm) {
		padding: 0;
	}
	.site-logo {
		transition: all 0.25s 0s ease;
		line-height: 0;
		@include media-breakpoint-down(sm) {
			padding: 10px 0;
		}
	}
	.site-nav {
		margin-top: 30px;
		.site-nav-toggle {
			display: none;
			position: fixed;
			top: 25px;
			right: 15px;
			transition: all 0.25s 0s ease;
			cursor: pointer;
			width: 30px;
			height: 23px;
			span {
				display: block;
				position: absolute;
				left: 0;
				top: 10px;
				width: 100%;
				height: 3px;
				background: $color-primary;
				border-radius: 3px;
				transition: all 0.25s 0s ease;
			}
			&::before,
			&::after {
				content: '';
				position: absolute;
				display: block;
				width: 100%;
				left: 0;
				height: 3px;
				background: $color-primary;
				border-radius: 3px;
				transition: all 0.25s 0s ease;
			}
			&::before {
				top: 0;
			}
			&::after {
				bottom: 0;
			}
			&.active {
				span {
					opacity: 0;
				}
				&::before {
					transform: rotate(-45deg) translateY(10px) translateX(-4px);
				}
				&::after {
					transform: rotate(45deg) translateY(-10px) translateX(-4px);
				}
			}
		}
		ul {
			display: flex;
			justify-content: center;
			font-family: "Noto Serif Japanese", $base-fonts;
			margin-bottom: 0;
			li {
				letter-spacing: 0.12em;
				@include font-size(18);
				margin: 0 1.5625rem;
				transition: all 0.25s 0s ease;
				a {
				}
			}
		}
		@include media-breakpoint-down(sm) {
			margin-top: 0;
			.site-nav-toggle {
				display: block;
			}
			ul {
				flex-flow: column wrap;
				li {
					width: 100%;
					margin: 0;
					letter-spacing: 0;
					border-bottom: 1px solid #c1ab9d;
					@include font-size(16);
					&:first-child {
						border-top: 1px solid #c1ab9d;
					}
					a {
						display: block;
						padding: 1em;
						&::after {
							@include font-awesome("\f105", bold);
							float: right;
						}
					}
				}
				&.active {
				}
				&.hidden-sm {
					display: none;
				}
			}
		}
	}
	// 追従
	&.is-sticky {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background: #fff;
		z-index: 2;
		box-shadow: 0 0 5px 0 rgba(#000, 0.15);
		padding-top: 0;
		.site-logo {
			width: 100px;
			padding: 15px 0;
		}
		.site-nav {
			margin-top: 0px;
			ul {
				li {
					@include font-size(16);
				}
			}
		}
		@include media-breakpoint-down(sm) {
			box-shadow: none;
			.site-nav-toggle {
				top: 21px;
			}
			.site-nav {
				ul {
					li {
						border-color: #ddd;
					}
				}
			}
			// .site-nav {
			// 	margin-top: 0px;
			// 	ul {
			// 		width: 100%;
			// 		border-bottom: 1px solid #bbb;
			// 		li {
			// 			width: 20%;
			// 			@include font-size(11);
			// 			font-family: $base-fonts;
			// 			border-right: 1px solid #ddd;
			// 			letter-spacing: 0;
			// 			margin: 0;
			// 			vertical-align: middle;
			// 			&:last-child {
			// 				border-right: 0;
			// 			}
			// 			a {
			// 				display: flex;
			// 				align-items: center;
			// 				justify-content: center;
			// 				padding: 1.5em 0.25em;
			// 				background: #eee;
			// 				width: 100%;
			// 				height: 100%;
			// 			}
			// 		}
			// 	}
			// }
		}
	}
}


// キービジュアル
.keyvisual {
	width: 1100px;
	margin-left: auto;
	margin-right: auto;
	.keyvisual-container {
		position: relative;
	}
	.slide .slick-item {
		padding: 1px;
		img {
			transform: translateY(-1px);
		}
	}
	svg {
		width: auto;
		height: auto;
		vertical-align: bottom;
		padding-bottom: 5px;
		display: block;
		transform: scale(1.001);
		path {
			height: 101%;
		}
		@media all and (-ms-high-contrast:none) {
			width: 1100px;
			height: 600px;
		}
		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			background: #f5ebd7;
		}
	}
	@include media-breakpoint-down(lg) {
		width: 100%;
		max-width: 1100px;
	}
	@include media-breakpoint-down(sm) {
		overflow: hidden;
		.keyvisual-container {
			width: 170%;
			transform: translateX(-20.5%);
			.slide .slick-item {
				padding: 5px;
				width: 100%;
				img {
					width: 100%;
				}
			}
			svg {
				width: 100%;
			}
		}
	}
}

#frame {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

// ごあいさつ
.greeting {
	background: url(../img/greeting/bg_greeting.png) no-repeat center top;
	padding-top: 2.5rem;
	padding-bottom: 100px;
	@include media-breakpoint-down(sm) {
		background: url(../img/greeting/bg_greeting_sp@2x.png) no-repeat center top;
		background-size: 1000px auto;
	}
	.sc-container {
		position: relative;
	}
	.section-image {
		img {
			border-radius: 50%;
			border: 5px solid #f6f0e4;
			position: absolute;
			@include media-breakpoint-down(lg) {
				transform: scale(0.8);
			}
			@include media-breakpoint-down(md) {
				display: none;
			}
		}
		.image-01 {
			top: -40px;
			left: 20px;
		}
		.image-02 {
			top: -50px;
			right: -40px;
			@include media-breakpoint-down(lg) {
				right: -20px;
			}
		}
	}
	.slick-thumbnail {
		margin-top: 5rem;
		margin-left: -10px;
		margin-right: -10px;
		@include media-breakpoint-down(sm) {
			margin-top: 3rem;
			margin-left: 0px;
			margin-right: 0px;
		}
		.slick-item {
			margin-left: 10px;
			margin-right: 10px;
			a {
				img {
					border-radius: 10px;
				}
			}
		}
		.slick-dots {
			li {
				button{
					&::before {
						color: #fff;
						opacity: 1;
					}
					&:hover {
						&::before {
							opacity: 0.8;
						}
					}
				}
				&.slick-active {
					button::before {
						color: $slick-dot-color-active;
					}
				}
			}
		}
		.slick-next,
		.slick-prev {
			width: 38px;
			height: 38px;
			&::before {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
			}
		}

		.slick-next {
			right: -38px;
			&::before {
				background: url(../img/btn_controller_right.png) no-repeat center center /cover;
			}
		}
		.slick-prev {
			left: -38px;
			&::before {
				background: url(../img/btn_controller_left.png) no-repeat center center /cover;
			}
		}
	}
}

// 犬舎環境
.environment {
	.sc-container {
		position: relative;
	}
	.section-image {
		img {
			border-radius: 50%;
			border: 5px solid #f6f0e4;
			position: absolute;
			@include media-breakpoint-down(lg) {
				transform: scale(0.8);
			}
			@include media-breakpoint-down(md) {
				display: none;
			}
		}
		.image-01 {
			top: 30px;
			left: 35px;
		}
		.image-02 {
			top: -50px;
			right: -40px;
			@include media-breakpoint-down(lg) {
				right: -20px;
			}
		}
	}
	.image-container {
		margin-top: 2.5rem;
		@include media-breakpoint-down(md) {
			padding-left: 15px;
			padding-right: 15px;
		}
		ul {
			display: flex;
			flex-flow: row wrap;
			margin: -1.875rem -7px 0;
			li {
				width: 33.33333%;
				margin-top: 1.875rem;
				@include media-breakpoint-down(lg) {
					padding-left: 10px;
					padding-right: 10px;
				}
				@include media-breakpoint-down(xs) {
					width: 50%;
				}
				a {
					img {
						border-radius: 10px;
					}
					p {
						@include font-size(14);
						margin: 0.5rem 0 0;
						text-align: center;
					}
				}
			}
		}
	}
}

// 運営サイト
.sites {
	background: #e4dbc8;
	padding-bottom: 100px;
	.sites-container {
		display: flex;
		justify-content: center;
		padding-top: 60px;
		@include media-breakpoint-down(sm) {
			flex-flow: column wrap;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 100px;
		}
		@include media-breakpoint-down(xs) {
			padding-top: 85px;
		}
		.item {
			width: 420px;
			border-radius: 30px;
			padding: 30px;
			margin-left: 15px;
			margin-right: 15px;
			position: relative;
			@include media-breakpoint-down(md) {
				width: 360px;
			}
			@include media-breakpoint-down(sm) {
				width: 100%;
				margin: 0;
				padding: 15px;
			}
			&.mame {
				background: #4a0000;
				.logo {
					width: 200px;
					padding-right: 20px;
				}
			}
			&.shiba {
				background: #001a33;
				@include media-breakpoint-down(sm) {
					margin-top: 6.5rem;
				}
				.logo {
					width: 200px;
					padding-left: 20px;
				}
			}
			.logo {
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 30px;
				@include media-breakpoint-down(sm) {
					margin-bottom: 15px;
				}
			}
			.hero {
				@include media-breakpoint-down(sm) {
					text-align: center;
				}
				img {
					border-radius: 20px;
					@include media-breakpoint-down(xs) {
						width: 100%;
					}
				}
			}
			.balloon {
				position: absolute;
				background: url(../img/sites/bg_balloon.svg) no-repeat center center;
				width: 375px;
				min-height: 110px;
				padding: 28px 20px 42px;
				top: -90px;
				left: 50%;
				transform: translateX(-50%);
				@include media-breakpoint-down(sm) {
					top: -100px;
				}
				@include media-breakpoint-down(xs) {
					width: 90%;
					top: -90px;
					margin: 0 auto;
					border-radius: 15px;
					padding: 15px;
					background: #fff;
					min-height: initial;
					@include font-size(14);
					&::after {
						content: '';
						bottom: -5px;
						border-width: 5px 5px 0 5px;
						border-style: solid;
						border-color: #fff transparent transparent transparent;
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
					}
					br {
						display: none;
					}
				}
				p {
					margin: 0;
					text-align: center;
					@include media-breakpoint-down(xs) {
						text-align: left;
					}
				}
			}
			.image-container {
				margin-top: 15px;
				@include media-breakpoint-down(lg) {
					padding-right: 0;
					padding-left: 0;
				}
				ul {
					margin-left: -5px;
					margin-right: -5px;
					display: flex;
					flex-flow: row wrap;
					li {
						width: 50%;
						padding: 5px;
						@include media-breakpoint-down(sm) {
							width: 25%;
						}
						@include media-breakpoint-down(xs) {
							width: 50%;
						}
						a {
							img {
								border-radius: 10px;
								@include media-breakpoint-down(xs) {
									width: 100%;
								}
							}
						}
					}
				}
			}
			.btn-container {
				padding: 0 30px;
				margin-top: 30px;
				@include media-breakpoint-down(md) {
					padding: 15px 15px 0;
					margin-top: 0;
				}
				@include media-breakpoint-down(sm) {
					padding-bottom: 15px;
				}
			}
			.btn {
				@include font-size(18);
				font-weight: bold;
				color: #fff;
				padding: 1.5rem;
				letter-spacing: 0.06em;
				line-height: 1;
				@include media-breakpoint-down(sm) {
					@include font-size(16);
					letter-spacing: 0;
				}
				i {
					@include font-size(14);
					transform: translateY(-0.05em);
				}
				&:hover {
					background: lighten($color-warning, 5%);
				}
			}
		}
	}
}

// アクセス
.access {
	padding-bottom: 80px;
	@include media-breakpoint-down(sm) {
		padding-bottom: 0px;
	}
	.access-container {
		display: flex;
		flex-flow: row wrap;
		@include media-breakpoint-down(md) {
			flex-flow: column wrap;
		}
	}
	.map {
		width: 690px;
		@include media-breakpoint-down(md) {
			width: 100%;
		}
		iframe {
			width: 100%;
			height: 460px;
			border: 0;
		}
	}
	.address {
		width: calc(100% - 690px);
		padding-left: 30px;
		padding-top: 20px;
		@include media-breakpoint-down(md) {
			width: 100%;
			padding: 20px;
		}
	}
	.btn {
		@include font-size(18);
		font-weight: bold;
		color: #fff;
		padding: 1.5rem;
		letter-spacing: 0.06em;
		line-height: 1;
		i {
			@include font-size(14);
			transform: translateY(-0.05em);
		}
		&:hover {
			background: lighten($color-warning, 5%);
		}
	}
}

// お問い合わせ（フッター）
.info {
	background: #e4dbc8;
	.site-logo {
		padding: 30px 0 10px;
		margin-bottom: 100px;
		@include media-breakpoint-down(sm) {
			margin-bottom: 0;
		}
	}
	.info-container {
		display: flex;
		justify-content: center;
		flex-flow: row wrap;
		margin-bottom: 20px;
		@include media-breakpoint-down(sm) {
			flex-flow: column wrap;
			margin-bottom: 0;
		}
		.item {
			width: 345px;
			position: relative;
			@include media-breakpoint-down(sm) {
				width: 100%;
				margin-top: 10px;
			}
			.balloon {
				background: url(../img/info/bg_balloon.svg) no-repeat center center;
				width: 250px;
				height: 97px;
				padding: 36px 15px 43px;
				@include font-size(18);
				letter-spacing: 0;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%) translateY(-100%);
				text-align: center;
				@include media-breakpoint-down(sm) {
					width: 90%;
					margin: 0 auto;
					border-radius: 15px;
					padding: 15px;
					background: #fff;
					min-height: initial;
					height: auto;
					position: relative;
					transform: initial;
					left: initial;
					top: initial;
					margin-bottom: 10px;
					@include font-size(14);
					&::after {
						content: '';
						bottom: -5px;
						border-width: 5px 5px 0 5px;
						border-style: solid;
						border-color: #fff transparent transparent transparent;
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
					}
					br {
						display: none;
					}
				}
			}
			ul {
				margin-top: -0.5rem;
				@include media-breakpoint-down(sm) {
					border-top: 1px solid #c1b9aa;
					margin-top: 0;
				}
				li {
					border-bottom: 1px solid #c1b9aa;
					a {
						display: flex;
						width: 100%;
						align-items: center;
						@include font-size(28);
						padding: 1.5625rem 0 1.5625rem 1rem;
						line-height: 1;
						@include media-breakpoint-down(sm) {
							@include font-size(20);

						}
						i {
							width: 2rem;
						}
						&::after {
							@include font-awesome('\f138', bold);
							@include font-size(16);
							color: #705647;
							margin-left: auto;
							margin-right: 5px;
							transition: margin 0.25s 0s ease;
						}
						&:hover, &:focus {
							text-decoration: none;
							&::after {
								margin-right: 0;
								color: #1a110b;
							}
						}
					}
				}
			}
			&:nth-child(2n-1) {
				ul {
					padding-right: 40px;
					border-right: 1px solid #c1b9aa;
					@include media-breakpoint-down(sm) {
						padding: 0;
						border-right: 0;
					}
				}
			}

			&:nth-child(2n) {
				ul {
					padding-left: 40px;
					@include media-breakpoint-down(sm) {
						padding: 0;
					}
				}
			}
		}
	}
	.copyright {
		background: url(../img/bg_footer.png) no-repeat center top;
		padding: 60px 0 30px;
		@include media-breakpoint-down(sm) {
			background: url(../img/bg_footer_sp@2x.png) no-repeat center top;
			background-size: 1200px auto;
		}
		p {
			text-align: center;
			@include font-size(14);
			color: #503422;
		}
	}
}