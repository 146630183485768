.title-section {
	@include font-size(28);
	font-family: "Noto Serif Japanese", $base-fonts;
	color: $color-primary;
	text-align: center;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	margin: 0;
	letter-spacing: 0.06em;
	@include media-breakpoint-down(sm) {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}
	&::before {
		content: '';
		display: inline-block;
		width: 31px;
		height: 27px;
		background: url(../img/icon_title.svg) no-repeat left top;
		margin-right: 0.5rem;
	}
}

.title-sub {
	@include font-size(20);
	font-weight: bold;
	padding: 0;
	margin: 0 0 1em;
}