@charset "utf-8";
// Mixin
@mixin font-awesome($content, $font-weight: normal) {
	content: $content;
	font-family: "Font Awesome 5 Free";
	-webkit-font-smoothing: antialiased;
	text-rendering: auto;
	font-weight: $font-weight;
}

// フォントサイズ指定
@mixin font-size($size, $important:'', $base: 16) {
	@if $important == 'important' {
		font-size: $size + px !important;
		font-size: ($size / $base) + rem !important;
	} @else {
		font-size: $size + px;
		font-size: ($size / $base) + rem;
	}
	
}

// リンク色一括指定
@mixin link-color($color, $text-decoration: underline, $lighten: 0%) {
	color: $color;

	&:hover, &:active, &:focus {
		color: lighten($color, $lighten);
		text-decoration: $text-decoration;
	}
}

// placeholderの文字色変更
@mixin placeholder-color($color) {
	&:placeholder-shown {
		color: $color;
	}
	&::-webkit-input-placeholder {
		color:$color;
	}
	&:-moz-placeholder {
		color:$color;
		opacity: 1;
	}
	&::-moz-placeholder {
		color:$color;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color:$color;
	}
}

// 三角形生成
@mixin triangle($color, $direction, $height, $width: null) {
	position: relative;
	&::before {
		content: '';
		position: absolute;
		border-style: solid;
		@content;
		@if $direction == 'top' {
			top: -$height;
			border-width: 0 $width $height $width;
			border-color: transparent transparent $color transparent;
		} @else if $direction == 'right' {
			right: -$height;
			border-width: $width 0 $width $height;
			border-color: transparent transparent transparent $color;
		} @else if $direction == 'bottom' {
			bottom: -$height;
			border-width: $height $width 0 $width;
			border-color: $color transparent transparent transparent;
		} @else if $direction == 'left' {
			left: -$height;
			border-width: $width $height $width 0;
			border-color: transparent #{ $color } transparent transparent;
		}
		@if length($direction) == 2 {
			$top-or-bottom: nth($direction, 1);
			$left-or-right: nth($direction, 2);
			@if $top-or-bottom == 'top' {
				@if $left-or-right == 'left' {
					border-width: $height $height 0 0;
					border-color: $color transparent transparent transparent;
				} @else if $left-or-right == 'right' {
					border-width: 0 $height $height 0;
					border-color: transparent $color transparent transparent;
				}
			} @else if $top-or-bottom == 'bottom' {
				@if $left-or-right == 'left' {
					border-width: $height 0 0 $height;
					border-color: transparent transparent transparent $color;
				} @else if $left-or-right == 'right' {
					border-width: 0 0 $height $height;
					border-color: transparent transparent $color transparent
				}
			}
		}
	}
}