@charset "utf-8";
@font-face {
	font-family: "Noto Serif Japanese";
	src: url('../fonts/NotoSerifJP-Regular.woff') format('woff');
	font-display: swap;
}

.preload * {
	transition: none !important;
}
$base-fonts: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", メイリオ, Meiryo, "ＭＳ Ｐゴシック", sans-serif;

// bootstrap
$body-color: #503422;
$line-height-lg: 1.625;
$line-height-sm: 1.625;
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1100px
);

$color-primary: #503422;
$color-warning: #d7931d;
$theme-colors: (
	primary: $color-primary,
	warning: $color-warning
);


// slick
$slick-font-path: "../fonts/";
$slick-loader-path: "../img/";
$slick-dot-size: 12px;
$slick-dot-color-active: #ea6d92;
$slick-dot-color: #847e73;
.slick-dots li {
	margin-left: 0.5rem !important;
	margin-right: 0.5rem !important;
}

// fontawesome
$fa-font-path: "../fonts/";